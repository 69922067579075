import cookies from "js-cookie";

const tokenName = 'token';

/**
 * 获得令牌
 * @returns {*}
 */
function getToken() {
    return cookies.get(this.tokenName);
}

/**
 * 设置令牌
 */
function setToken(token) {
    cookies.set(this.tokenName, token);
}

/**
 * 获得昵称
 * @returns {string}
 */
function getNickname() {
    return localStorage.getItem('nickname');
}

/**
 * 获得权限
 * @returns {Array}
 */
function getPermissions() {
    return JSON.parse(localStorage.getItem('permissions'));
}

/**
 * 清空授权信息
 */
function clear() {
    cookies.remove(this.tokenName, { path: '/' });
    localStorage.removeItem('nickname');
    localStorage.removeItem('permissions');
}

/**
 * 检测权限
 * @param to 前往哪个路由
 * @param from 离开哪个路由
 * @param next
 */
function checkAccessBeforeEach(to, from, next) {
    //获取本地保存的token
    let token = this.getToken();
    //忽略检测的路径
    let ignorePaths = ['/login', '/error/not-found', '/error/denied'];
    //如果目标路由是登录则直接进入登录页面
    if (ignorePaths.indexOf(to.path) != -1) {
        next();
    } else {
        //如果本地没有token则跳转到登录页面
        if (token == undefined) {
            next({
                path: '/login'
            });
        }
    }
}

/**
 * 检测访问权限
 * @param permission 待检测的权限
 * @returns {boolean} 有权限=true 无权限=false
 */
function checkAccess(permission = '') {
    if (permission == '') {
        return true;
    }
    //本地保存的权限
    let permissions = localStorage.getItem('permissions');
    //如果本地没有则返回无权限
    if (permissions == null || permissions == undefined) {
        return false;
    }
    permissions = JSON.parse(permissions).map((item) => {
        return item.name;
    });
    if (permissions.indexOf(permission) == -1) {
        return false;
    }
    return true;
}

export default {
    tokenName,
    setToken,
    getToken,
    getNickname,
    getPermissions,
    clear,
    checkAccessBeforeEach,
    checkAccess,
}
