let code = {

    //正常
    OK: 200,

    //未授权
    UNAUTHORIZED: 400,

};
export default {
    code
}
