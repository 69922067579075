import axios from "axios";
import response from "@/common/constants/Response";
import authorize from "@/common/utils/Authorize";

let api = axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL,
    timeout: 15000,
    headers: {
        'Content-Type': 'application/json',
    }
});
api.interceptors.request.use((config) => {
    //检测是否有Token
    let token = authorize.getToken();
    if (token !== undefined) {
        // config.headers.Authorization = token;
    }
    return config;
});
api.interceptors.response.use((res) => {
    //检查是否是JSON
    if (res.data.code) {
        //是否未授权，如果是则跳转到登录页
        if (res.data.code == response.code.UNAUTHORIZED) {
            authorize.clear();
            window.location = '/login';
        }
    }
    return res;
});

//资源
let resource = {
    //基地址
    _baseUrl: process.env.VUE_APP_RESOURCE_BASE_URL,

    /**
     * 获得资源基地址
     * @returns {string}
     */
    get baseUrl() {
        return this._baseUrl;
    }
}
export default {
    api: api,
    resource: resource,
}
